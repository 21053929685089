.youtube {
    iframe {
        height: 100%;
        width: 100%;
    }
}
.play-button {
    position: absolute;
    width:100%;
    height: 100%;
    top:0;
    left:0;

    display: flex;
    justify-content: center;
    align-items: center;

    > a, > span {
        background-color: $global-primary-color;
        color: white;
        border-radius: 100px;
        padding: 10px 10px 10px 10px;

        box-shadow: rgba(0, 0, 0, 0.0862745) 0px 5px 8px 0px;

        transition: all .35s;

        &:hover {
            color: $link-text-hover-color;
            background-color: $global-link-hover-color;
            box-shadow: rgba(0, 0, 0, 0.0862745) 0px 10px 16px 0px;
        }

        > span {
            margin-left: 2px;
            margin-right: -2px;
        }
    }
}

.uk-cover-container {
    video {
        object-fit: cover;
    }

    &.home {
        video {
            background-color: black;

            @media screen and (max-width: $breakpoint-medium) {
                object-position: 33% 50%;
            }
        }
    }
}
.work-preview {
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

// .iframe-container {
//     position: relative;
//     overflow: hidden;
//     padding-top: 56.25%;
//
//
//     iframe {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         border: 0;
//     }
// }
// #modal-media {
//     iframe {
//         max-height: 100vh;
//     }
// }

// .fullscreen-video {
//     height: 100vh;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }
//
// .iframe-container {
//     width: 100%;
//     position: relative;
//     overflow: hidden;
//     padding-top: 56.25%;
//     //
//     // display: flex;
//     // justify-content: center;
//     // align-items: center;
//     @media (orientation: landscape) {
//         max-height: 100vh;
//     }
//
//     iframe {
//         // z-index: -1;
//         // @media (orientation: landscape) {
//         //     max-height: 100vh;
//         // }
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         border: 0;
//
//     }
// }
