@charset 'utf-8';

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('../fonts/open-sans-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v16-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/open-sans-v16-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
       url('../fonts/open-sans-v16-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v16-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v16-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v16-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v16-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}


// 1. Your custom variables and variable overwrites.
@import "variables.scss";

// 2. Import default variables and available mixins.
@import "~uikit/src/scss/mixins-theme.scss";

// 3. Your custom mixin overwrites.
// @mixin hook-card() { color: #000; }

// 4. Import UIkit.
@import "~uikit/src/scss/uikit-theme.scss";

// 4. Import Custom styles.
@import "custom/custom";
