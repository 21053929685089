.uk-navbar {
    z-index: 1;
    transition: padding .5s;
    padding: 0;
    min-height: 80px;

    .uk-navbar-item {

    }
    .uk-logo {
        padding: 0;
        height: 70px;
        width: 70px;
        transition: transform .5s;
        -webkit-tap-highlight-color: transparent;

        &:hover {
            transform: rotate(360deg);
        }
    }
    &.is-home:not(.uk-navbar-sticky) {
        @media screen and (min-width: $breakpoint-small) {
            padding: 3rem 0;

            .uk-logo {
                width: 120px;
                height: 120px;

            }
            .uk-navbar-nav {
                a {
                    font-size: $global-medium-font-size;
                }
            }
        }
        .uk-navbar-nav {
            a {
                color: white ;

                &:hover {
                    color: darken(white , 10%);
                }
            }
        }
    }


    // &.uk-navbar-sticky {
    //     padding:0;
    //
    //
    //     .uk-navbar-nav {
    //         a {
    //             font-size: $global-small-font-size;
    //             color: $global-primary-color ;
    //         }
    //         a:hover, &.active {
    //             color: darken($global-primary-color , 20%);
    //         }
    //     }
    // }

    .uk-navbar-nav {
        // text-transform: uppercase;
        font-weight: 800;

    }
}

#overlay-nav {
    .nav-home {
        position: absolute;
        top: 0;
        left: 0;
        padding: $global-margin;
        text-transform: uppercase;
    }
}
