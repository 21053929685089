body, html {
    hyphens: auto;
}

.text-white {
    color: white;

    h1,h2,h3,h4,h5,h6 {
        color: white;
    }
}
