.work-item {
    hyphens: auto;
    .work-item-info {
        > div > div {
            height: 80px;
        }
        // > div > div > div {
        //
        //     background-color: pink;
        // }
        // h4 {
        //     @media screen and (max-width: $breakpoint-medium) {
        //         font-size: $global-small-font-size;
        //     }
        // }
        // > div {
        //     height: 80px;
        // }
    }
}
